// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#scroll-container::-webkit-scrollbar {
  width: 10px;
}
#scroll-container::-webkit-scrollbar-track {
  background: #ccc;
  border-radius: 20px;
  margin-left: 10px;
}
#scroll-container::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Tesa.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":["#scroll-container::-webkit-scrollbar {\n  width: 10px;\n}\n#scroll-container::-webkit-scrollbar-track {\n  background: #ccc;\n  border-radius: 20px;\n  margin-left: 10px;\n}\n#scroll-container::-webkit-scrollbar-thumb {\n  background-color: #eee;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
