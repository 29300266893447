import React, { useState, useEffect } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import { HOST_NAME } from "../const";
import ProgressBar from "@ramonak/react-progress-bar";

Chart.register(...registerables);

const PhysiologicalScore = () => {
  const [categoryScore, setCategoryScore] = useState();
  const [leverScore, setLeverScore] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const recommendation = [
    {
      High: "Your commitment to maintaining balanced nutrition, regular exercise, excellent posture, and ensuring adequate rest is truly remarkable. Your dedication to staying active and energized is inspiring, and you continue to set a shining example for those around you. Keep up the great work and continue leading with your exceptional focus on health and well-being!",
    },
    {
      Medium:
        "You're making great strides in your overall health, and your progress is truly commendable! With just a bit more attention to your exercise, posture, nutrition, and rest, you can unlock even more of your potential. You're on a steady path, and each small improvement will bring you closer to your goals. Keep up the momentum—you're on the right track, and the progress you've made so far is something to be proud of!",
    },
    {
      Low: "While there is room for improvement, know that every great journey starts with small, consistent steps. Begin by focusing on enhancing your exercise routine, eating healthier, and ensuring you get enough rest. With a little effort, you’ll start seeing positive changes. Remember, you have the potential to make meaningful progress, and we're here to support and guide you every step of the way. The power to improve is within you!",
    },
  ];
  const [recData, setRecData] = useState();
  useEffect(() => {
    const path = window.location.pathname;
    const lastSegment = path.split("/").filter(Boolean).pop();

    const fetchScore = async () => {
      await axios
        .post(`${HOST_NAME}/report/score-uuid`, {
          uuid: lastSegment,
        })
        .then(async (response) => {
          // setCategoryScore(res.data.data[0]["Physiological Health"].categories);
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const physiologicalHealth =
              response.data.data[0]["Physiological Health"];
            if (physiologicalHealth && physiologicalHealth.categories) {
              setCategoryScore(physiologicalHealth.categories);
              setLeverScore(
                response.data.data[0]["Physiological Health"].score
              );

              if (
                response.data.data[0]["Physiological Health"].score > 0 &&
                response.data.data[0]["Physiological Health"].score <= 30
              ) {
                setRecData(recommendation[0].High);
              }
              if (
                response.data.data[0]["Physiological Health"].score > 30 &&
                response.data.data[0]["Physiological Health"].score <= 70
              ) {
                setRecData(recommendation[1].Medium);
              }
              if (
                response.data.data[0]["Physiological Health"].score > 70 &&
                response.data.data[0]["Physiological Health"].score <= 100
              ) {
                setRecData(recommendation[2].Low);
              }
            }
          }
          console.log("Updated categoryScore:", categoryScore);
        })
        .catch((err) => console.log(err));
    };
    fetchScore();

    const fetchResponse = async () => {
      await axios
        .post(`${HOST_NAME}/report/get-ind-res-data`, {
          uuid: lastSegment,
        })
        .then((res) => {
          setResponseData(res.data.data.physiological);
        })
        .catch((err) => console.log(err));
    };
    fetchResponse();
  }, []);

  // const data = {
  //   datasets: [
  //     {
  //       data: [40, 60],
  //       backgroundColor: ["#278AFF", "#ccc"],
  //     },
  //   ],
  // };

  // const bardata = {
  //   labels: ["Well-being", "Movement", "Energy"],
  //   datasets: [
  //     {
  //       backgroundColor: "#2860B5",
  //       data: [50, 35, 34],
  //       borderRadius: 10,
  //       borderSkipped: false,
  //     },
  //   ],
  // };

  const colorData = {
    1: "orange",
    2: "orange",
    3: "gray",
    4: "green",
    5: "green",
  };

  // const bardata = {
  //   labels: ["Well-being"],
  //   datasets: [
  //     {
  //       backgroundColor: "#2860B5",
  //       data: [20, null],
  //       borderRadius: {
  //         topLeft: 0,
  //         topRight: 0,
  //         bottomLeft: 10,
  //         bottomRight: 10,
  //       },
  //       borderSkipped: false,
  //       barThickness: 50,
  //       stack: "Stack 0",
  //     },
  //     {
  //       backgroundColor: "#ccc",
  //       data: [80, null],
  //       borderRadius: {
  //         topLeft: 10,
  //         topRight: 10,
  //         bottomLeft: 0,
  //         bottomRight: 0,
  //       },
  //       borderSkipped: false,
  //       barThickness: 50,
  //       stack: "Stack 0",
  //     },
  //   ],
  // };

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,

        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
  };
  console.log(categoryScore);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexDirection: "column",
        // overflowY: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
          width: "70%",
          // height: "auto",
          padding: "30px",
          borderRadius: "12px",
          backgroundColor: "rgba(255,255,255,0.5)",
          flexDirection: "column",
        }}
      >
        <p style={{ width: "80%" }}>
          Physiological Health assesses if people take up an active role in
          enhancing their physical well-being for optimal performance and
          productivity.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div style={{ width: "150px", height: "150px", marginLeft: "50px" }}>
            <Doughnut
              data={{
                datasets: [
                  {
                    data:
                      leverScore !== null
                        ? [leverScore, 100 - leverScore]
                        : [0, 100],
                    backgroundColor: ["#2860B5", "rgba(0,0,0,0.1)"],
                    // backgroundColor: ["#278AFF", "#ccc"],
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                rotation: 0,
                circumference: 360,
                cutout: "70%",
                maintainAspectRatio: true,
                responsive: true,
              }}
            />
            <p
              style={{
                fontWeight: "400",
                fontSize: "18px",
                color: "#2961B6",
                marginTop: "-100px",
                marginLeft: "55px",
              }}
            >
              Score <br /> {Math.ceil(leverScore)}%
            </p>
          </div>

          <div
            className={{
              maxWidth: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "250px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {categoryScore &&
              Array.isArray(categoryScore) &&
              categoryScore.length > 0 ? (
                categoryScore.map((score) => (
                  <Bar
                    style={{
                      height: "250px",
                      width: "250px",
                    }}
                    data={{
                      labels: Object.keys(score),
                      datasets: [
                        {
                          backgroundColor: "#2860B5",
                          data: [Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 0,
                            topRight: 0,
                            bottomLeft: 10,
                            bottomRight: 10,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                        {
                          backgroundColor: "rgba(0,0,0,0.1)",
                          data: [100 - Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 10,
                            topRight: 10,
                            bottomLeft: 0,
                            bottomRight: 0,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                      ],
                    }}
                    options={options}
                  />
                ))
              ) : (
                <div>No data available</div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div style={{ width: "50%" }}>
            <p className="font-bold text-xl">Well-being</p>
            <p>I am encouraged to prioritize my health by the Company</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[0])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[0]) : 0
              }
            />
            <p>
              I take consistent action towards my health and wellbeing everyday
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[1])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[1]) : 0
              }
            />
            <p className="font-bold text-xl">Movement</p>
            <p>I consciously move myself from my desk during working hours</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[2])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[2]) : 0
              }
            />
            <p className="font-bold text-xl">Energy</p>
            <p>
              My sleep quality has NOT affected my productivity and performance
              at work
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[3])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[3]) : 0
              }
            />
            <p>
              I feel physically refreshed and rejuvenated after waking up from
              my night’s sleep
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[4])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[4]) : 0
              }
            />

            <p>I am able to stay energetic throughout the day</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[5])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[5]) : 0
              }
            />
          </div>
          <div
            style={{
              backgroundColor: "#C3D6F4",
              width: "40%",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <p>{recData}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhysiologicalScore;
