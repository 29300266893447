import React from "react";

const Invalid = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Invalid route</h1>
    </div>
  );
};

export default Invalid;
