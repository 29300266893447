import React, { useState, useEffect, useRef } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import { HOST_NAME } from "../const";
import ProgressBar from "@ramonak/react-progress-bar";

Chart.register(...registerables);

const SpiritualScore = () => {
  const [categoryScore, setCategoryScore] = useState();
  const [leverScore, setLeverScore] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const recommendation = [
    {
      High: "Your alignment with both purpose and impact is truly remarkable! Your deep sense of purpose shines through in your work, reflecting a strong understanding of your own significance and the contributions you make. Not only are you fulfilling your potential, but your dedication to giving back and making a positive difference in the community is inspiring. Your selflessness and commitment are creating lasting change, and you are setting a shining example for those around you. Keep up the incredible work as you continue to lead with purpose and impact!",
    },
    {
      Medium:
        "You're making great progress in aligning your career with your life’s purpose and contributing to the community. While you're well on your way, there's still room to find deeper fulfillment and make an even greater impact. By continuing to reflect on your contributions and dedicating a little more time or resources, you’ll unlock more of that inner sense of purpose and significance. Every step you take toward this path matters, and you’re building something truly meaningful. Keep going, you're on the right track!",
    },
    {
      Low: "Though your sense of purpose may seem distant, Start with simple efforts, and remember, every contribution counts. We’re here to support you in finding meaningful ways to give back.",
    },
  ];
  const [recData, setRecData] = useState();
  useEffect(() => {
    const path = window.location.pathname;
    const lastSegment = path.split("/").filter(Boolean).pop();

    const fetchScore = async () => {
      await axios
        .post(`${HOST_NAME}/report/score-uuid`, {
          uuid: lastSegment,
        })
        .then(async (response) => {
          // setCategoryScore(res.data.data[0]["Physiological Health"].categories);
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const physiologicalHealth =
              response.data.data[1]["Spiritual Health"];
            if (physiologicalHealth && physiologicalHealth.categories) {
              setCategoryScore(physiologicalHealth.categories);
              setLeverScore(response.data.data[1]["Spiritual Health"].score);

              if (
                response.data.data[1]["Spiritual Health"].score > 0 &&
                response.data.data[1]["Spiritual Health"].score <= 30
              ) {
                setRecData(recommendation[0].High);
              }
              if (
                response.data.data[1]["Spiritual Health"].score > 30 &&
                response.data.data[1]["Spiritual Health"].score <= 70
              ) {
                setRecData(recommendation[1].Medium);
              }
              if (
                response.data.data[1]["Spiritual Health"].score > 70 &&
                response.data.data[1]["Spiritual Health"].score <= 100
              ) {
                setRecData(recommendation[2].Low);
              }
            }
          }
        })
        .catch((err) => console.log(err));
    };
    fetchScore();

    const fetchResponse = async () => {
      await axios
        .post(`${HOST_NAME}/report/get-ind-res-data`, {
          uuid: lastSegment,
        })
        .then((res) => {
          setResponseData(res.data.data.spiritual);
        })
        .catch((err) => console.log(err));
    };
    fetchResponse();
  }, []);

  // const data = {
  //   datasets: [
  //     {
  //       data: [40, 60],
  //       backgroundColor: ["#278AFF", "#ccc"],
  //     },
  //   ],
  // };

  // const bardata = {
  //   labels: ["Well-being", "Movement", "Energy"],
  //   datasets: [
  //     {
  //       backgroundColor: "#2860B5",
  //       data: [50, 35, 34],
  //       borderRadius: 10,
  //       borderSkipped: false,
  //     },
  //   ],
  // };

  const colorData = {
    1: "orange",
    2: "orange",
    3: "gray",
    4: "green",
    5: "green",
  };

  // const bardata = {
  //   labels: ["Well-being"],
  //   datasets: [
  //     {
  //       backgroundColor: "#2860B5",
  //       data: [20, null],
  //       borderRadius: {
  //         topLeft: 0,
  //         topRight: 0,
  //         bottomLeft: 10,
  //         bottomRight: 10,
  //       },
  //       borderSkipped: false,
  //       barThickness: 50,
  //       stack: "Stack 0",
  //     },
  //     {
  //       backgroundColor: "#ccc",
  //       data: [80, null],
  //       borderRadius: {
  //         topLeft: 10,
  //         topRight: 10,
  //         bottomLeft: 0,
  //         bottomRight: 0,
  //       },
  //       borderSkipped: false,
  //       barThickness: 50,
  //       stack: "Stack 0",
  //     },
  //   ],
  // };

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,

        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
  };
  console.log(categoryScore);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexDirection: "column",
        // overflowY: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
          width: "70%",
          // height: "auto",
          padding: "30px",
          borderRadius: "12px",
          backgroundColor: "rgba(255,255,255,0.5)",
          flexDirection: "column",
        }}
      >
        <p style={{ width: "80%" }}>
          Spiritual Health assesses the alignment of individual purpose and
          their inclination to a larger societal mission
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div style={{ width: "150px", height: "150px", marginLeft: "50px" }}>
            <Doughnut
              data={{
                datasets: [
                  {
                    data:
                      leverScore !== null
                        ? [leverScore, 100 - leverScore]
                        : [0, 100],
                    backgroundColor: ["#2860B5", "rgba(0,0,0,0.1)"],
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                rotation: 0,
                circumference: 360,
                cutout: "70%",
                maintainAspectRatio: true,
                responsive: true,
              }}
            />
            <p
              style={{
                fontWeight: "400",
                fontSize: "18px",
                color: "#2961B6",
                marginTop: "-100px",
                marginLeft: "55px",
              }}
            >
              Score <br /> {Math.ceil(leverScore)}%
            </p>
          </div>

          <div
            className={{
              maxWidth: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "250px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {categoryScore &&
              Array.isArray(categoryScore) &&
              categoryScore.length > 0 ? (
                categoryScore.map((score) => (
                  <Bar
                    style={{
                      height: "250px",
                      width: "250px",
                    }}
                    data={{
                      labels: Object.keys(score),
                      datasets: [
                        {
                          backgroundColor: "#2860B5",
                          data: [Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 0,
                            topRight: 0,
                            bottomLeft: 10,
                            bottomRight: 10,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                        {
                          backgroundColor: "rgba(0,0,0,0.1)",
                          data: [100 - Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 10,
                            topRight: 10,
                            bottomLeft: 0,
                            bottomRight: 0,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                      ],
                    }}
                    options={options}
                  />
                ))
              ) : (
                <div>No data available</div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div style={{ width: "50%" }}>
            <p className="font-bold text-xl">Purpose</p>
            <p>My work gives me energy rather than draining out energy</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[0])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[0]) : 0
              }
            />
            <p>I am aware of my purpose in both my life and career</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[1])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[1]) : 0
              }
            />
            <p className="font-bold text-xl">Impact</p>
            <p>My Organization is a socially responsible Company</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[2])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[2]) : 0
              }
            />
            <p>
              I receive sufficient opportunities at work to contribute to social
              causes
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[3])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[3]) : 0
              }
            />
            <p>
              My work and its impact on others give me a sense of fulfillment
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[4])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[4]) : 0
              }
            />
            <p>
              I have a mindfulness practice that I incorporate into my routine
              to support my well-being
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[4])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[4]) : 0
              }
            />
          </div>
          <div
            style={{
              backgroundColor: "#C3D6F4",
              width: "40%",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <p>{recData}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpiritualScore;
