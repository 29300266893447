import React, { useState, useEffect } from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { IoIosArrowBack } from "react-icons/io";
import Physiological from "./Physiological";
import Financial from "./Financial";
import Spiritual from "./Spiritual";
import Tesa from "./Tesa";
import { HOST_NAME } from "../const";
import Cookies from "js-cookie";
import axios from "axios";
// import { useSearchParams } from "react-router-dom";

// import { v4 as uuidv4 } from "uuid";
// import { browserName } from "react-device-detect";
import { replace, useNavigate, useParams } from "react-router-dom";

const Home = () => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState(null);
  //   const [token, setToken] = useState();
  //   const [categoryScore, setCategoryScore] = useState();
  const { lastSegment } = useParams();

  // const [searchParams] = useSearchParams();
  // const lastSegment = searchParams.get("uuid");
  const navigate = useNavigate();

  useEffect(() => {
    const userLogin = async () => {
      // if (!Cookies.get("token")) {
      await axios
        .post(`${HOST_NAME}/report/create-user`, {
          uuid: lastSegment,
        })
        .then((res) => {
          console.log(res.data.token);
          Cookies.set("token", res.data.token);
          // setToken(res.data.token);
          // const createUser = async () => {
          //   await axios
          //     .post(`${TESA_HOST}/report/create-user`, {
          //       deviceId: uuidv4(),
          //       employee: lastSegment,
          //       deviceModel: browserName,
          //       location: "India",
          //     })
          //     .then((res) => {
          //       Cookies.set("deviceInfo", res.data.data.userData._id);
          //     })
          //     .catch((err) => console.log(err));
          // };
        })
        .catch((e) => {
          console.log(e);
        });

      await axios
        .post(`${HOST_NAME}/report/get-name`, {
          uuid: lastSegment,
        })
        .then((res) => {
          setName(res.data.name);
        })
        .catch((e) => {
          console.log(e);
        });

      await axios
        .post(`${HOST_NAME}/report/open-count`, {
          uuid: lastSegment,
        })
        .then((res) => {
          console.log("Count updated");
        })
        .catch((e) => {
          console.log(e);
        });

      // const fetchScore = async () => {
      //   console.log("hello");
      //   await axios
      //     .post(`${HOST_NAME}/report/score-uuid`, {
      //       uuid: lastSegment,
      //     })
      //     .then((res) => {
      //       setCategoryScore(res.data.data);
      //     })
      //     .catch((err) => console.log(err));
      // };
      // fetchScore();
    };
    userLogin();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("physiological");
  // const [showChat, setShowChat] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const handleChatClick = () => {
  //   setShowChat(!showChat);
  // };

  const renderContent = () => {
    switch (activeTab) {
      case "physiological":
        return <Physiological />;
      case "financial":
        return <Financial />;
      case "spiritual":
        return <Spiritual />;
      case "tesa":
        return <Tesa />;
      default:
        return <p>Welcome to the Home Page!</p>;
    }
  };

  if (visible) {
    return (
      <div className="flex">
        <div
          style={{ position: "fixed" }}
          className={`bg-white fixed top-0 left-0 h-screen border-r-2  transition-transform transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } md:relative md:translate-x-0 w-64`}
        >
          <div className="w-full flex-row-reverse flex md:hidden">
            <IoIosArrowBack
              className="mr-3 mt-5"
              size={30}
              color="#000"
              onClick={toggleSidebar}
            />
          </div>

          <div className="flex justify-center content-center m-4">
            <img
              className="w-10/12"
              src={require("../assets/genesis-logo.png")}
              alt="twp-logo"
            />
          </div>
          <ul>
            <li
              className={`p-3 mx-4 my-3 rounded-lg flex justify-center content-center cursor-pointer  ${
                activeTab === "physiological"
                  ? "bg-[rgba(40,96,181,0.2)]"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                handleTabClick("physiological");
                toggleSidebar();
              }}
            >
              Physiological Health
            </li>
            <li
              className={`p-3 mx-4 my-3 rounded-lg flex justify-center content-center cursor-pointer ${
                activeTab === "financial"
                  ? "bg-[rgba(40,96,181,0.2)]"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                handleTabClick("financial");
                toggleSidebar();
              }}
            >
              Financial Health
            </li>
            <li
              className={`p-3 mx-4 my-3 rounded-lg flex justify-center content-center cursor-pointer ${
                activeTab === "spiritual"
                  ? "bg-[rgba(40,96,181,0.2)]"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                handleTabClick("spiritual");
                toggleSidebar();
              }}
            >
              Spiritual Health
            </li>
            <li
              style={{ border: "6px solid #ADCDFD" }}
              className={`p-2 mx-4 rounded-lg flex justify-center content-center cursor-pointer ${
                activeTab === "tesa"
                  ? "bg-[rgba(40,96,181,0.2)]"
                  : "hover:bg-gray-200"
              }`}
              onClick={() => {
                handleTabClick("tesa");
                toggleSidebar();
              }}
            >
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  position: "relative",
                  left: "-10px",
                }}
                src={require("../assets/sparkles.png")}
              />
              <span>Curate Your Habit</span>
            </li>
          </ul>
          <div className="fixed bottom-6 w-full flex justify-center content-center">
            <img
              className="w-9/12"
              src={require("../assets/twp-logo.png")}
              alt="twp-logo"
            />
          </div>
        </div>

        {/* Main content */}
        <div className="flex-1 ml-36 p-4 bg-gradient-to-br from-[#FEFEFE] to-[#BDD8FF]">
          <div className="md:hidden p-2 text-white" onClick={toggleSidebar}>
            <HiMenuAlt2 size={30} color="#000" />
          </div>

          {renderContent()}
        </div>
        {/* <button
        class="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-16 h-16 bg-black hover:bg-gray-700 m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900"
        type="button"
        aria-haspopup="dialog"
        aria-expanded="false"
        data-state="closed"
        onClick={() => handleChatClick()}
      >
        <svg
          xmlns=" http://www.w3.org/2000/svg"
          width="30"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="text-white block border-gray-200 align-middle"
        >
          <path
            d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z"
            class="border-gray-200"
          ></path>
        </svg>
      </button> */}
      </div>
    );
  } else {
    const letsgo = async () => {
      setVisible(true);
      // const path = window.location.pathname;
      // const lastSegment = path.split("/").filter(Boolean).pop();
      // Cookies.set("token", lastSegment);
      // await axios
      //   .post(`${HOST_NAME}/report/create-user`, {
      //     uuid: lastSegment,
      //   })
      //   .then((res) => {
      //     Cookies.set("token", res.data.token);
      //     setToken(res.data.token);
      //     const createUser = async () => {
      //       await axios
      //         .post(`${TESA_HOST}/report/create-user`, {
      //           deviceId: uuidv4(),
      //           employee: lastSegment,
      //           deviceModel: browserName,
      //           location: "India",
      //         })
      //         .then((res) => {
      //           Cookies.set("deviceInfo", res.data.data.userData._id);
      //         })
      //         .catch((err) => console.log(err));
      //     };
      //     createUser();
      //   })
      //   .catch((e) => console.log(e));
    };
    return (
      <div
        className="bg-gradient-to-br from-[#FEFEFE] to-[#BDD8FF]"
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        <img
          alt="rocket-image"
          style={{
            width: "70px",
            height: "50px",
            position: "absolute",
            marginTop: "8px",
            marginLeft: "8px",
          }}
          src={require("../assets/rocket.png")}
        />
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "45%",
                height: "70%",
                backgroundColor: "#fff",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.1)",
              }}
            >
              <div className="flex justify-center content-center flex-col">
                <span className="text-[#2961B6] text-5xl ml-12">
                  Hi {name !== null ? name : "!"}
                </span>
                <span className="m-6 text-center">
                  Welcome to your Thrive Report
                </span>
              </div>
              <span className="w-3/5 text-center">
                This is your guide to help you understand current status across
                health levers alongside Tesa, your AI Growth Partner. You'll
                receive personalized recommendations and resources to take
                mindful steps toward becoming your holistic self.
              </span>
              <div
                onClick={() => letsgo()}
                className="bg-[#2961B6] w-[50%] h-[40px] rounded-xl flex justify-center content-center cursor-pointer mt-5"
              >
                <span className="mt-2 text-[#fff]">Let's Go!</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Home;
